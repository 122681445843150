import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 8 ) should always be at the top of the file we use them for price strings.
   */
  {
    pid: 0,
    lpSymbol: 'ECOLAB',
    lpAddresses: {
      97: '',
      56: '0x25331ba95a10375b765C0e2614E48621A1357D00',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  // {
  //   pid: 1,
  //   lpSymbol: 'ECOLAB-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xf1a2403897c2683bb7aa08ef6aebce89407e1987',
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'ECOLAB-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xa73d0ece8ffd1da16be5c8cc7876feab2804fb56',
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.busd,
  // },
  {
    pid: 1,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  /* Add new farms below this line */
  // {
  //   pid: 3,
  //   lpSymbol: 'BEES-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xbd3285fc029eb16d1a74d2489fc41b008057f706',
  //   },
  //   token: tokens.bees,
  //   quoteToken: tokens.wbnb,
  // },

]

export default farms
