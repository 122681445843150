import { ChainId, Token } from '@pancakeswap/sdk'

export const ECOLAB: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x25331ba95a10375b765C0e2614E48621A1357D00',
    18,
    'ECOLAB',
    'Labswap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0x25331ba95a10375b765C0e2614E48621A1357D00',
    18,
    'ECOLAB',
    'Labswap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}

export const WBNB = new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
)

const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  avo: {
    symbol: 'AVO',
    address: {
      56: '0xDA5d7C36513F13D275dd46CA2Bd2ADD566412A15',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://avocadotoken.io/',
  },
  mwf: {
    symbol: 'MWF',
    address: {
      56: '0x7281c8d16e5ece362540959d07922034887836d1',
      97: '',
    },
    decimals: 9,
    projectLink: 'Https://metaWarfare-official.io/',
  },
  kmz: {
    symbol: 'KMZ',
    address: {
      56: '0x9E1E967936347C608A2c42596CB809B3AA96366b',
      97: '',
    },
    decimals: 4,
    projectLink: 'https://kamikazetoken.com/',
  },
  praise: {
    symbol: 'PRAISE',
    address: {
      56: '0x2a64ab26cd76f349144920E8a7A59C0D6B7F2967',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://praisetokens.pro/',
  },
  shib: {
    symbol: 'SHIB',
    address: {
      56: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://shibatoken.com/',
  },
  tem: {
    symbol: 'TEM',
    address: {
      56: '0x19e6BfC1A6e4B042Fb20531244D47E252445df01',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://templar.finance/',
  },
  tinc: {
    symbol: 'TINC',
    address: {
      56: '0x05aD6E30A855BE07AfA57e08a4f30d00810a402e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://tinyworlds.io/',
  },
  ceek: {
    symbol: 'CEEK',
    address: {
      56: '0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.ceek.com/',
  },
  lilx: {
    symbol: 'LILX',
    address: {
      56: '0x96694e2F40635Fa178f14C13772C02e614A4D5D2',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://t.me/lil_x_floki',
  },
  csix: {
    symbol: 'CSIX',
    address: {
      56: '0x04756126F044634C9a0f0E985e60c88a51ACC206',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://carbon.website/',
  },
  cake: {
    symbol: 'ECOLAB',
    address: {
      56: '0x25331ba95a10375b765C0e2614E48621A1357D00',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://labswapecosystem.com/',
  },
  bees: {
    symbol: 'BEES',
    address: {
      56: '0x25261bD17A1FF9D992E03A4bfBCb312e74f4db80',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://beestoken.com/',
  },
  fork: {
    symbol: 'CAKE',
    address: {
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  chess: {
    symbol: 'CHESS',
    address: {
      56: '0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://tranchess.com/',
  },
  high: {
    symbol: 'HIGH',
    address: {
      56: '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.highstreet.market/',
  },
  titan: {
    symbol: 'TITAN',
    address: {
      56: '0xe898EDc43920F357A93083F1d4460437dE6dAeC2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://titanswap.org',
  },
  harmony: {
    symbol: 'ONE',
    address: {
      56: '0x03fF0ff224f904be3118461335064bB48Df47938',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.harmony.one/',
  },
  mask: {
    symbol: 'MASK',
    address: {
      56: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mask.io/',
  },
  dvi: {
    symbol: 'DVI',
    address: {
      56: '0x758FB037A375F17c7e195CC634D77dA4F554255B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dvision.network/',
  },
  adx: {
    symbol: 'ADX',
    address: {
      56: '0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.adex.network',
  },
  bscpad: {
    symbol: 'BSCPAD',
    address: {
      56: '0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bscpad.com/',
  },
  rabbit: {
    symbol: 'RABBIT',
    address: {
      56: '0x95a1199EBA84ac5f19546519e287d43D2F0E1b41',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://rabbitfinance.io/earn',
  },
  form: {
    symbol: 'FORM',
    address: {
      56: '0x25A528af62e56512A19ce8c3cAB427807c28CC19',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://formation.fi/',
  },
  txl: {
    symbol: 'TXL',
    address: {
      56: '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://tixl.org/',
  },
  orbs: {
    symbol: 'ORBS',
    address: {
      56: '0xeBd49b26169e1b52c04cFd19FCf289405dF55F80',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.orbs.com/',
  },
  cos: {
    symbol: 'COS',
    address: {
      56: '0x96Dd399F9c3AFda1F194182F71600F1B65946501',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.contentos.io/',
  },
  bunny: {
    symbol: 'BUNNY',
    address: {
      56: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://pancakebunny.finance/',
  },
  alice: {
    symbol: 'ALICE',
    address: {
      56: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://www.myneighboralice.com/',
  },
  for: {
    symbol: 'FOR',
    address: {
      56: '0x658A109C5900BC6d2357c87549B651670E5b0539',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.for.tube/home',
  },
  bux: {
    symbol: 'BUX',
    address: {
      56: '0x211FfbE424b90e25a15531ca322adF1559779E45',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://getbux.com/bux-crypto/',
  },
  nuls: {
    symbol: 'NULS',
    address: {
      56: '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://www.nuls.io/',
  },
  belt: {
    symbol: 'BELT',
    address: {
      56: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://beta.belt.fi/',
  },
  ramp: {
    symbol: 'RAMP',
    address: {
      56: '0x8519EA49c997f50cefFa444d240fB655e89248Aa',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://rampdefi.com/',
  },
  bfi: {
    symbol: 'BFI',
    address: {
      56: '0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bearn.fi/',
  },
  dexe: {
    symbol: 'DEXE',
    address: {
      56: '0x039cB485212f996A9DBb85A9a75d898F94d38dA6',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dexe.network/',
  },
  bel: {
    symbol: 'BEL',
    address: {
      56: '0x8443f091997f06a61670B735ED92734F5628692F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bella.fi/',
  },
  tpt: {
    symbol: 'TPT',
    address: {
      56: '0xECa41281c24451168a37211F0bc2b8645AF45092',
      97: '',
    },
    decimals: 4,
    projectLink: 'https://www.tokenpocket.pro/',
  },
  watch: {
    symbol: 'WATCH',
    address: {
      56: '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://yieldwatch.net/',
  },
  xmark: {
    symbol: 'xMARK',
    address: {
      56: '0x26A5dFab467d4f58fB266648CAe769503CEC9580',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://benchmarkprotocol.finance/',
  },
  bmxx: {
    symbol: 'bMXX',
    address: {
      56: '0x4131b87F74415190425ccD873048C708F8005823',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://multiplier.finance/',
  },
  iotx: {
    symbol: 'IOTX',
    address: {
      56: '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://iotex.io/',
  },
  bor: {
    symbol: 'BOR',
    address: {
      56: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.boringdao.com/',
  },
  bopen: {
    symbol: 'bOPEN',
    address: {
      56: '0xF35262a9d427F96d2437379eF090db986eaE5d42',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://opendao.io/',
  },
  dodo: {
    symbol: 'DODO',
    address: {
      56: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dodoex.io/',
  },
  swingby: {
    symbol: 'SWINGBY',
    address: {
      56: '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swingby.network/',
  },
  bry: {
    symbol: 'BRY',
    address: {
      56: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://berrydata.co/',
  },
  zee: {
    symbol: 'ZEE',
    address: {
      56: '0x44754455564474A89358B2C2265883DF993b12F0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://zeroswap.io/',
  },
  swgb: {
    symbol: 'SWGb',
    address: {
      56: '0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swirgepay.com/',
  },
  swg: {
    symbol: 'SWG',
    address: {
      56: '0xe792f64C582698b8572AAF765bDC426AC3aEfb6B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swirgepay.com/',
  },
  sfp: {
    symbol: 'SFP',
    address: {
      56: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.safepal.io/',
  },
  lina: {
    symbol: 'LINA',
    address: {
      56: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://linear.finance/',
  },
  lit: {
    symbol: 'LIT',
    address: {
      56: '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.litentry.com/',
  },
  hget: {
    symbol: 'HGET',
    address: {
      56: '0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://www.hedget.com/',
  },
  bdo: {
    symbol: 'BDO',
    address: {
      56: '0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bdollar.fi/',
  },
  egld: {
    symbol: 'EGLD',
    address: {
      56: '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://elrond.com/',
  },
  ust: {
    symbol: 'UST',
    address: {
      56: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  wsote: {
    symbol: 'wSOTE',
    address: {
      56: '0x541E619858737031A1244A5d0Cd47E5ef480342c',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://soteria.finance/#/',
  },
  front: {
    symbol: 'FRONT',
    address: {
      56: '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://frontier.xyz/',
  },
  helmet: {
    symbol: 'Helmet',
    address: {
      56: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.helmet.insure/',
  },
  btcst: {
    symbol: 'BTCST',
    address: {
      56: '0x78650B139471520656b9E7aA7A5e9276814a38e9',
      97: '',
    },
    decimals: 17,
    projectLink: 'https://www.1-b.tc/',
  },
  bscx: {
    symbol: 'BSCX',
    address: {
      56: '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bscex.org/',
  },
  ten: {
    symbol: 'TEN',
    address: {
      56: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.tenet.farm/',
  },
  balbt: {
    symbol: 'bALBT',
    address: {
      56: '0x72fAa679E1008Ad8382959FF48E392042A8b06f7',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://allianceblock.io/',
  },
  asr: {
    symbol: 'ASR',
    address: {
      56: '0x80D5f92C2c8C682070C95495313dDB680B267320',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  atm: {
    symbol: 'ATM',
    address: {
      56: '0x25E9d05365c867E59C1904E7463Af9F312296f9E',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  og: {
    symbol: 'OG',
    address: {
      56: '0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  reef: {
    symbol: 'REEF',
    address: {
      56: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://reef.finance/',
  },
  ditto: {
    symbol: 'DITTO',
    address: {
      56: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://ditto.money/',
  },
  juv: {
    symbol: 'JUV',
    address: {
      56: '0xC40C9A843E1c6D01b7578284a9028854f6683b1B',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  psg: {
    symbol: 'PSG',
    address: {
      56: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  vai: {
    symbol: 'VAI',
    address: {
      56: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://labswap.com/',
  },
  blink: {
    symbol: 'BLINK',
    address: {
      56: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://blink.wink.org',
  },
  unfi: {
    symbol: 'UNFI',
    address: {
      56: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://unifiprotocol.com',
  },
  twt: {
    symbol: 'TWT',
    address: {
      56: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://trustwallet.com/',
  },
  hard: {
    symbol: 'HARD',
    address: {
      56: '0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://hard.kava.io',
  },
  broobee: {
    symbol: 'bROOBEE',
    address: {
      56: '0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://roobee.io/',
  },
  stax: {
    symbol: 'STAX',
    address: {
      56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://stablexswap.com/',
  },
  nar: {
    symbol: 'NAR',
    address: {
      56: '0xA1303E6199b319a891b79685F0537D289af1FC83',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://narwhalswap.org/',
  },
  nya: {
    symbol: 'NYA',
    address: {
      56: '0xbFa0841F7a90c4CE6643f651756EE340991F99D5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://nyanswop.org/',
  },
  ctk: {
    symbol: 'CTK',
    address: {
      56: '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://www.certik.foundation/',
  },
  inj: {
    symbol: 'INJ',
    address: {
      56: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://injectiveprotocol.com/',
  },
  sxp: {
    symbol: 'SXP',
    address: {
      56: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swipe.io/',
  },
  alpha: {
    symbol: 'ALPHA',
    address: {
      56: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://alphafinance.io/',
  },
  xvs: {
    symbol: 'XVS',
    address: {
      56: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  sushi: {
    symbol: 'SUSHI',
    address: {
      56: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://sushi.com/',
  },
  comp: {
    symbol: 'COMP',
    address: {
      56: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://compound.finance/',
  },
  syrup: {
    symbol: 'LABBAR',
    address: {
      56: '0xD1703D944bcaA74779bEE55a6A42CC3617168a8D',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://labswapecosystem.com',
  },
  bifi: {
    symbol: 'BIFI',
    address: {
      56: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://beefy.finance/',
  },
  dusk: {
    symbol: 'DUSK',
    address: {
      56: '0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dusk.network/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  eth: {
    symbol: 'ETH',
    address: {
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/',
  },
  beth: {
    symbol: 'BETH',
    address: {
      56: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/eth2/beacon-chain/',
  },
  mamzn: {
    symbol: 'mAMZN',
    address: {
      56: '0x3947B992DC0147D2D89dF0392213781b04B25075',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mgoogl: {
    symbol: 'mGOOGL',
    address: {
      56: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mnflx: {
    symbol: 'mNFLX',
    address: {
      56: '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mtsla: {
    symbol: 'mTSLA',
    address: {
      56: '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  ltc: {
    symbol: 'LTC',
    address: {
      56: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://litecoin.org/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/usdc',
  },
  dai: {
    symbol: 'DAI',
    address: {
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.makerdao.com/',
  },
  ada: {
    symbol: 'ADA',
    address: {
      56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://www.cardano.org/',
  },
  band: {
    symbol: 'BAND',
    address: {
      56: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bandprotocol.com/',
  },
  dot: {
    symbol: 'DOT',
    address: {
      56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://polkadot.network/',
  },
  eos: {
    symbol: 'EOS',
    address: {
      56: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://eos.io/',
  },
  link: {
    symbol: 'LINK',
    address: {
      56: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://chain.link/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059fF775485246999027B3197955',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  xrp: {
    symbol: 'XRP',
    address: {
      56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://ripple.com/xrp/',
  },
  atom: {
    symbol: 'ATOM',
    address: {
      56: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://cosmos.network/',
  },
  yfii: {
    symbol: 'YFII',
    address: {
      56: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://dfi.money/#/',
  },
  xtz: {
    symbol: 'XTZ',
    address: {
      56: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://www.tezos.com/',
  },
  bch: {
    symbol: 'BCH',
    address: {
      56: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bch.info/',
  },
  yfi: {
    symbol: 'YFI',
    address: {
      56: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://yearn.finance/',
  },
  uni: {
    symbol: 'UNI',
    address: {
      56: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://uniswap.org/',
  },
  fil: {
    symbol: 'FIL',
    address: {
      56: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://filecoin.io/',
  },
  bake: {
    symbol: 'BAKE',
    address: {
      56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bakeryswap.org/',
  },
  burger: {
    symbol: 'BURGER',
    address: {
      56: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://burgerswap.org/',
  },
  bdigg: {
    symbol: 'bDIGG',
    address: {
      56: '0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://badger.finance/',
  },
  bbadger: {
    symbol: 'bBadger',
    address: {
      56: '0x1F7216fdB338247512Ec99715587bb97BBf96eae',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://badger.finance/',
  },
  trade: {
    symbol: 'TRADE',
    address: {
      56: '0x7af173F350D916358AF3e218Bdf2178494Beb748',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://unitrade.app/',
  },
  pnt: {
    symbol: 'PNT',
    address: {
      56: '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ptokens.io/',
  },
  mir: {
    symbol: 'MIR',
    address: {
      56: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  pbtc: {
    symbol: 'pBTC',
    address: {
      56: '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ptokens.io/',
  },
  lto: {
    symbol: 'LTO',
    address: {
      56: '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ltonetwork.com/',
  },
  pcws: {
    symbol: 'pCWS',
    address: {
      56: '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://game.seascape.network/',
  },
  zil: {
    symbol: 'ZIL',
    address: {
      56: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
      97: '',
    },
    decimals: 12,
    projectLink: 'https://www.zilliqa.com/',
  },
  lien: {
    symbol: 'LIEN',
    address: {
      56: '0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://lien.finance/',
  },
  swth: {
    symbol: 'SWTH',
    address: {
      56: '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://switcheo.network/',
  },
  dft: {
    symbol: 'DFT',
    address: {
      56: '0x42712dF5009c20fee340B245b510c0395896cF6e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.dfuture.com/home',
  },
  gum: {
    symbol: 'GUM',
    address: {
      56: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://gourmetgalaxy.io/',
  },
  dego: {
    symbol: 'DEGO',
    address: {
      56: '0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bsc.dego.finance/home',
  },
  nrv: {
    symbol: 'NRV',
    address: {
      56: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://nerve.fi/',
  },
  easy: {
    symbol: 'EASY',
    address: {
      56: '0x7C17c8bED8d14bAccE824D020f994F4880D6Ab3B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://easyfi.network/',
  },
  oddz: {
    symbol: 'ODDZ',
    address: {
      56: '0xCD40F2670CF58720b694968698A5514e924F742d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://oddz.fi/',
  },
  hoo: {
    symbol: 'HOO',
    address: {
      56: '0xE1d1F66215998786110Ba0102ef558b22224C016',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://hoo.com/',
  },
  apys: {
    symbol: 'APYS',
    address: {
      56: '0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://apyswap.com/',
  },
  bondly: {
    symbol: 'BONDLY',
    address: {
      56: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bondly.finance/',
  },
  tko: {
    symbol: 'TKO',
    address: {
      56: '0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.tokocrypto.com/',
  },
  itam: {
    symbol: 'ITAM',
    address: {
      56: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://itam.network/',
  },
  arpa: {
    symbol: 'ARPA',
    address: {
      56: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://arpachain.io/',
  },
  eps: {
    symbol: 'EPS',
    address: {
      56: '0xA7f552078dcC247C2684336020c03648500C6d9F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ellipsis.finance/',
  },
  jgn: {
    symbol: 'JGN',
    address: {
      56: '0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://jgndefi.com/',
  },
  tlm: {
    symbol: 'TLM',
    address: {
      56: '0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
      97: '',
    },
    decimals: 4,
    projectLink: 'https://alienworlds.io/',
  },
  perl: {
    symbol: 'PERL',
    address: {
      56: '0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://perlinx.finance/',
  },
  alpa: {
    symbol: 'ALPA',
    address: {
      56: '0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bsc.alpaca.city/',
  },
  hzn: {
    symbol: 'HZN',
    address: {
      56: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://horizonprotocol.com/',
  },
  suter: {
    symbol: 'SUTER',
    address: {
      56: '0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://shield.suterusu.io/',
  },
  cgg: {
    symbol: 'CGG',
    address: {
      56: '0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://chainguardians.io/',
  },
  mix: {
    symbol: 'MIX',
    address: {
      56: '0xB67754f5b4C704A24d2db68e661b2875a4dDD197',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mixie.chainguardians.io/',
  },
  hakka: {
    symbol: 'HAKKA',
    address: {
      56: '0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://hakka.finance/',
  },
  xed: {
    symbol: 'XED',
    address: {
      56: '0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.exeedme.com/',
  },
  τbtc: {
    symbol: 'τBTC',
    address: {
      56: '0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://www.btcst.finance/',
  },
  alpaca: {
    symbol: 'ALPACA',
    address: {
      56: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.alpacafinance.org/',
  },
  dfd: {
    symbol: 'DFD',
    address: {
      56: '0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dusd.finance/',
  },
  lmt: {
    symbol: 'LMT',
    address: {
      56: '0x9617857E191354dbEA0b714d78Bc59e57C411087',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://lympo.io/lmt/',
  },
  btt: {
    symbol: 'BTT',
    address: {
      56: '0x8595F9dA7b868b1822194fAEd312235E43007b49',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bittorrent.com/',
  },
  trx: {
    symbol: 'TRX',
    address: {
      56: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://tron.network/',
  },
  win: {
    symbol: 'WIN',
    address: {
      56: '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://winklink.org/',
  },
  mcoin: {
    symbol: 'mCOIN',
    address: {
      56: '0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  math: {
    symbol: 'MATH',
    address: {
      56: '0xF218184Af829Cf2b0019F8E6F0b2423498a36983',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mathwallet.org/',
  },
  kun: {
    symbol: 'KUN',
    address: {
      56: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://chemix.io/home',
  },
  qsd: {
    symbol: 'QSD',
    address: {
      56: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://chemix.io/home',
  },
  hyfi: {
    symbol: 'HYFI',
    address: {
      56: '0x9a319b959e33369C5eaA494a770117eE3e585318',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://hyfi.pro/#/',
  },
  oin: {
    symbol: 'OIN',
    address: {
      56: '0x658E64FFcF40D240A43D52CA9342140316Ae44fA',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://oin.finance/',
  },
  doge: {
    symbol: 'DOGE',
    address: {
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://dogecoin.com/',
  },
  fine: {
    symbol: 'FINE',
    address: {
      56: '0x4e6415a5727ea08aAE4580057187923aeC331227',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://refinable.com/',
  },
  one: {
    symbol: 'ONE',
    address: {
      56: '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bigone.com/',
  },
  pmon: {
    symbol: 'PMON',
    address: {
      56: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://polkamon.com/',
  },
  hotcross: {
    symbol: 'HOTCROSS',
    address: {
      56: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.hotcross.com/',
  },
  τdoge: {
    symbol: 'τDOGE',
    address: {
      56: '0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://www.btcst.finance/',
  },
  btr: {
    symbol: 'BTR',
    address: {
      56: '0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bitrue.com/',
  },
  ubxt: {
    symbol: 'UBXT',
    address: {
      56: '0xBbEB90cFb6FAFa1F69AA130B7341089AbeEF5811',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://upbots.com/',
  },
  wmass: {
    symbol: 'WMASS',
    address: {
      56: '0x7e396BfC8a2f84748701167c2d622F041A1D7a17',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://massnet.org/en/',
  },
  rfox: {
    symbol: 'RFOX',
    address: {
      56: '0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.redfoxlabs.io/',
  },
  xend: {
    symbol: 'XEND',
    address: {
      56: '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://xend.finance/',
  },
  cyc: {
    symbol: 'CYC',
    address: {
      56: '0x810EE35443639348aDbbC467b33310d2AB43c168',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://cyclone.xyz/',
  },
  chr: {
    symbol: 'CHR',
    address: {
      56: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://chromia.com/',
  },
  kalm: {
    symbol: 'KALM',
    address: {
      56: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://kalmar.io/',
  },
  deri: {
    symbol: 'DERI',
    address: {
      56: '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://deri.finance/#/index',
  },
  well: {
    symbol: 'WELL',
    address: {
      56: '0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bitwellex.com/',
  },
  wex: {
    symbol: 'WEX',
    address: {
      56: '0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://wault.finance/',
  },
  waultx: {
    symbol: 'WAULTx',
    address: {
      56: '0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://wault.finance/',
  },
  popen: {
    symbol: 'pOPEN',
    address: {
      56: '0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://opendao.io/',
  },
  ez: {
    symbol: 'EZ',
    address: {
      56: '0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://easyfi.network/',
  },
  vrt: {
    symbol: 'VRT',
    address: {
      56: '0x5F84ce30DC3cF7909101C69086c50De191895883',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  tusd: {
    symbol: 'TUSD',
    address: {
      56: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.trueusd.com/',
  },
  mtrg: {
    symbol: 'MTRG',
    address: {
      56: '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.meter.io/',
  },
  ktn: {
    symbol: 'KTN',
    address: {
      56: '0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://kattana.io/',
  },
  qkc: {
    symbol: 'QKC',
    address: {
      56: '0xA1434F1FC3F437fa33F7a781E041961C0205B5Da',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://quarkchain.io/',
  },
  bcfx: {
    symbol: 'bCFX',
    address: {
      56: '0x045c4324039dA91c52C55DF5D785385Aab073DcF',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.confluxnetwork.org/',
  },
  mx: {
    symbol: 'MX',
    address: {
      56: '0x9F882567A62a5560d147d64871776EeA72Df41D3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.mxc.com/',
  },
  ata: {
    symbol: 'ATA',
    address: {
      56: '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.ata.network/',
  },
  mbox: {
    symbol: 'MBOX',
    address: {
      56: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.mobox.io/#/',
  },
  boring: {
    symbol: 'BORING',
    address: {
      56: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.boringdao.com/',
  },
  marsh: {
    symbol: 'MARSH',
    address: {
      56: '0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://unmarshal.io/',
  },
  ampl: {
    symbol: 'AMPL',
    address: {
      56: '0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://www.ampleforth.org/',
  },
  o3: {
    symbol: 'O3',
    address: {
      56: '0xEe9801669C6138E84bD50dEB500827b776777d28',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://o3swap.com/',
  },
  hai: {
    symbol: 'HAI',
    address: {
      56: '0xaA9E582e5751d703F85912903bacADdFed26484C',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://hacken.io/',
  },
  htb: {
    symbol: 'HTB',
    address: {
      56: '0x4e840AADD28DA189B9906674B4Afcb77C128d9ea',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.hotbit.io/',
  },
  woo: {
    symbol: 'WOO',
    address: {
      56: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://woo.network/',
  },
  $dg: {
    symbol: '$DG',
    address: {
      56: '0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://decentral.games/',
  },
}

export default tokens
